<template>
  <AutoComplete
  v-model="model"
  field="cardname"
  inputClass="form-control"
  :suggestions="values"
  :dropdown="false"
  placeholder="Ingrese la busqueda..."
  :class="classes"
  :disabled="disabled"
  @item-select="onChange"
  @change="onChangeValue($event)"
  @complete="onComplete($event)"
  >
    <template #item="{ item }">
      <div>
        <span>{{item.cardname}}</span>
      </div>
    </template>
  </AutoComplete>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import suppliersFindName from '../../../../../services/searchSuppliers'
import AutoComplete from 'primevue/autocomplete'
export default defineComponent({
  name: 'AutoCompleteProveedores',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    supplierType: {
      type: Number,
      default: null
    }
  },
  components: {
    AutoComplete
  },
  setup (props, context) {
    const values = ref([])
    const model = ref({
      cardcode: '',
      cardname: ''
    })
    const onComplete = ({ query }) => {
      return suppliersFindName({ value: query.trim(), providerType: props.supplierType }).then(({ data }) => {
        values.value = data
        context.emit('setSupplier', { cardcode: '', cardname: query.trim(), wtliable: 'N' })
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onChangeValue = (event) => {
        context.emit('setSupplier', { cardcode: '', cardname: event.target.value, wtliable: 'N' })
    }
    const onChange = ({ value }) => {
      context.emit('update:modelValue', value.cardcode)
    }
    watch(() => props.modelValue, (value) => {
      setSupliers()
    })
    const setSupliers = () => {
      // values.value = []
      if (props.modelValue) {
        suppliersFindName({ value: props.modelValue, providerType: props.supplierType }).then(({ data }) => {
          if (!data.length || data.length > 1) {
            context.emit('setSupplier', { cardcode: '', cardname: props.modelValue, wtliable: 'N' })
            model.value = { cardcode: '', cardname: props.modelValue, wtliable: 'N' }
            values.value = data
          } else if (data.length === 1) {
            // values.value = data
            model.value = data[0]
            context.emit('setSupplier', data[0])
          }
          context.emit('update:modelValue', props.modelValue)
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    onMounted(() => {
      setSupliers()
    })
    return {
      model,
      values,
      onComplete,
      onChangeValue,
      onChange
    }
  }
})
</script>
